import React from "react";
import tw from "twin.macro";
import Grid from "~components/styles/Grid.jsx";
import * as T from "~components/styles/Typography.jsx";
import Go from "~components/Go.jsx";

const Footer = () => {
  const links = [
    {
      name: `CONTACT`,
      target: `/contact`
    },
    {
      name: `FAQ`,
      target: `/faq`
    },
    {
      name: `SHIPPING`,
      target: `/shipping`
    },
    {
      name: `T’s & C’s`,
      target: `/terms`
    },
    {
      name: `INSTAGRAM`,
      target: `https://www.instagram.com/`
    },
    {
      name: `FACEBOOK`,
      target: `https://www.facebook.com/`
    },
    {
      name: `TWITTER`,
      target: `https://www.twitter.com/`
    }
  ];

  return (
    <footer tw="relative pt-12 pb-12 bg-onyx text-white">
      <Grid node="nav">
        <ul tw="col-span-8 col-start-3 relative flex items-center justify-between">
          {links.map(({ name, target }) => (
            <li key={name} tw="relative">
              <Go to={target}>
                <T.Caption
                  font="b1"
                  level="4"
                  styles={[tw`uppercase text-white`]}
                >
                  {name}
                </T.Caption>
              </Go>
            </li>
          ))}
        </ul>
      </Grid>
    </footer>
  );
};

export default Footer;
